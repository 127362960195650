import { Component, forwardRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CUSTOM_RANGE, DateRangeName } from "../../service/date-range.service";
import { getExportContextServiceProvider } from "../../shared/provider/export-context-service.provider";
import { getThingContextServiceProvider } from "../../shared/provider/thing-context-service.provider";

@Component({
    selector: 'quick-history-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span *ngIf="title">{{ title | capitalize | localize }}</span>
            <span *ngIf="showSuffix">&nbsp;</span>
            <span *ngIf="showSuffix" custom-label="quickHistoryTitleSuffixProperty"></span>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
                <multi-metric-list-widget [mode]="'TABLE'" [exportEnabled]="true" [initialPeriodEmpty]="true" [styleClass]="'no-shadow-box no-margin-box'" [thingId]="thingId" [pageSize]="50" [filterPeriods]="filterPeriods" [ignoreGlobalExportButton]="true">
                    <metric *ngFor="let metric of metrics" [name]="metric.name" [filter]="metric.filter" [label]="metric.label" [unit]="metric.unit"></metric>
                </multi-metric-list-widget>
        </mat-dialog-content>
    `,
    viewProviders: [getThingContextServiceProvider(null, false), getExportContextServiceProvider(false)],
    styles: [`
        ::ng-deep .daterangepicker.dropdown-menu {
                z-index: 999999 !important;
            }
        }
    `]
})
export class QuickHistoryDialogComponent {

    title: string;
    thingId: string;
    metrics: { name: string, label: string, filter: string, unit: string }[];
    showSuffix: boolean;
    filterPeriods: string[];

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<QuickHistoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.title = data.title;
        this.thingId = data.thingId;
        this.metrics = data.metrics || [];
        if (this.metrics.length == 1) {
            this.metrics[0].label = "Value";
        }
        this.showSuffix = data.showSuffix;
        this.filterPeriods = [DateRangeName.TODAY, DateRangeName.YESTERDAY, DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, CUSTOM_RANGE];
    }
}