import { Component, ContentChild, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID, forwardRef } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Metric, MetricType, NetworkMetric, Thing, ValueRangeSeverity } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { DataService } from '../../service/data.service';
import { DateRangeName } from '../../service/date-range.service';
import { FieldService } from '../../service/field.service';
import { NetworkDataService } from '../../service/network-data.service';
import { StatisticService } from '../../service/statistic.service';
import { WearStatusService } from '../../service/wear-status.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { MetricAggregationType, MetricDetailComponent, StatisticComponent } from '../../shared/component';
import { LoaderPipe } from '../../shared/pipe';
import { AmChart5Component } from '../amchart5/am-chart5.component';
import { MicroChartService } from './micro-chart.service';
import { DataUtility } from '../../utility/data-utility';

@Component({
    selector: 'micro-chart-widget',
    template: require('./micro-chart.component.html'),
    styles: [`
        .title {
            font-size: 16px;
            margin-bottom: 12px;
        }
        frame-custom-box ::ng-deep div.card {
            margin-bottom: 0px;
        }
    `],
    providers: [MicroChartService]
})
export class MicroChartComponent extends AmChart5Component implements OnInit, OnDestroy {

    @Input() title: string;

    @Input() description: string;

    @Input() layout: MicroChartLayout = MicroChartLayout.LINE;

    @Input() defaultPeriodValue: DateRangeName = DateRangeName.LAST_30_DAYS;

    @Input() periodRef: string;

    @Input() queryFieldRef: string;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() height: string = '119px';

    @Input() color: string;

    @Input() minScale: number = 0;

    @Input() maxScale: number;

    @ContentChild(MetricDetailComponent) private metricComponent: MetricDetailComponent;

    @ContentChild(StatisticComponent) private statisticComponent: StatisticComponent;

    chartId: string;
    private thing: Thing;
    private series: any;
    private xAxis: any;
    private subscription: Subscription;
    private statisticFieldsNames: string[];
    private dynamicValues: { [metricId: string]: any } = {};
    private intervalId: any;
    private locale: string;
    private timezone: string;
    private yAxis: any;
    private wearMetricStandardUsage: number;

    static nextId = 0;

    private CHART_COLORS = {
        'GREY': '#e0e0e0',
        'NEUTRAL': '#000000',
        'DEFAULT': '#000000',
        'NORMAL': '#00b85b',
        'WARNING': '#F28A29',
        'CRITICAL': '#DF2316'
    }

    private readonly DOWNSAMPLE_COUNT = 150;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        @Inject(forwardRef(() => NgZone)) zone: NgZone,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => MicroChartService)) private microChartService: MicroChartService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => WearStatusService)) private wearStatusService: WearStatusService
    ) {
        super(platformId, zone, ['xy', 'percent'], 'micro');
    }

    ngOnInit(): void {
        this.chartId = `micro-chart-${++MicroChartComponent.nextId}`;
        const user = this.authenticationService.getUser();
        this.thing = this.thingContextService.getCurrentThing();
        this.locale = user.locale || user.language || 'en';
        this.timezone = user.timezone || 'UTC';
        if (this.color) {
            this.CHART_COLORS['DEFAULT'] = this.color;
        }
    }

    ngOnDestroy(): void {
        this.fieldService.unsubscribeFromFields([this.queryFieldRef, this.periodRef]);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.statisticFieldsNames) {
            this.fieldService.unsubscribeFromFields(this.statisticFieldsNames);
        }
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    protected getChartId(): string {
        return this.chartId;
    }

    protected initChart(): void {
        if (this.timezone == 'UTC') {
            this.root.utc = true
        } else {
            this.root.timezone = this.am5.Timezone.new(this.timezone);
        }

        if (this.layout == MicroChartLayout.PIE) {
            this.initPieChart();
        } else {
            this.initXYChart();
        }
        if (this.metricComponent) {
            this.processMetricDetailComponent();
        } else if (this.statisticComponent) {
            this.processStatisticComponent();
        }
    }

    private processMetricDetailComponent(): void {
        this.microChartService.getMetric(this.metricComponent.name, this.thing).then(metric => {
            if (!metric) {
                throw new Error('Error: metric is not defined');
            }
            if (this.thing) {
                this.setDynamicValues(metric as Metric);
            }
            let aggregation;
            if (metric.type == MetricType.ALGORITHM) {
                aggregation = this.metricComponent.aggregation;
            } else {
                aggregation = this.metricComponent.aggregation || MetricAggregationType.AVG_DAYS_1;
            }
            if (metric.type == MetricType.WEAR) {
                if (!this.thing) {
                    throw new Error('Error: missing thing');
                }
                this.wearStatusService.getWearStatusesFromThingIdAndMetricId(this.thing.id, metric.id).then(results => {
                    this.wearMetricStandardUsage = results[0].standardUsage;
                    this.subscribeFieldService(metric, aggregation);
                });
            } else {
                this.subscribeFieldService(metric, aggregation);
            }
        });
    }

    private subscribeFieldService(metric: Metric | NetworkMetric, aggregation: MetricAggregationType): void {
        this.subscription = this.fieldService.subscribeToFields([this.queryFieldRef, this.periodRef]).subscribe(fieldsMap => {
            this.refreshMetric(metric, aggregation, fieldsMap);
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
                this.refreshMetric(metric, aggregation, fieldsMap);
            }, 30000);
        })
    }

    private setDynamicValues(metric: Metric): void {
        this.thingContextService.getMetrics().then(metrics => {
            if (metric['minMetricId']) {
                const minMetric = metrics.find(m => m.id == metric['minMetricId']);
                this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, minMetric.name)
                    .then(result => this.dynamicValues[metric['minMetricId']] = result);
            }
            if (metric['maxMetricId']) {
                const maxMetric = metrics.find(m => m.id == metric['maxMetricId']);
                this.dataService.getLastValueByThingIdAndMetricName(this.thing.id, maxMetric.name)
                    .then(result => this.dynamicValues[metric['maxMetricId']] = result);
            }
        });
    }

    private refreshMetric(metric: Metric | NetworkMetric, aggregation: MetricAggregationType, fieldsMap: any): void {
        const params = this.microChartService.buildRequestParams(this.defaultPeriodValue, aggregation, fieldsMap, this.queryFieldRef, this.periodRef);
        let promise;
        if (this.thing) {
            promise = this.dataService.getValues(metric.name, this.thing.id, 10000, params);
        } else {
            promise = this.networkDataService.getValues(metric.name, this.contextService.getCurrentLocation().id, 10000, params);
        }
        promise.then(result => {
            const values = result.values;
            let valuesWithSeverity = [];
            if (values?.length) {
                valuesWithSeverity = values.reverse().map(v => {
                    if (this.metricComponent.filter) {
                        v.value = this.loaderPipe.transform(v.value, this.metricComponent.filter, true);
                    }
                    v['severity'] = this.microChartService.getSeverity(metric, v.value, !this.thing, this.dynamicValues);
                    v['color'] = this.getChartColor(v.value, v.severity);
                    if (this.layout == MicroChartLayout.LINE) {
                        this.checkScale(v.value);
                    }
                    return v;
                });
                valuesWithSeverity = DataUtility.downsampleData(valuesWithSeverity, this.DOWNSAMPLE_COUNT, 'timestamp', 'value')
                valuesWithSeverity[valuesWithSeverity.length - 1].last = true;
            }
            this.series.data.setAll(valuesWithSeverity);
            if (valuesWithSeverity?.length && this.layout == MicroChartLayout.LINE) {
                this.series.set('fill', this.am5.color(valuesWithSeverity[valuesWithSeverity.length - 1]?.['color']));
                this.series.set('stroke', this.am5.color(valuesWithSeverity[valuesWithSeverity.length - 1]?.['color']));
            }
            let metricPeriod = params.get('aggregation');
            if (!aggregation && (metric as any).algorithmsComputationPeriod) {
                metricPeriod = (metric as any).algorithmsComputationPeriod
            }
            if (this.layout != MicroChartLayout.PIE) {
                this.updateAxisProperties(metricPeriod);
            } else {
                this.updatePieProperties(metricPeriod);
            }
        });
    }

    private checkScale(value: any): void {
        if (this.minScale != null && value < this.minScale) {
            this.minScale = value;
            this.yAxis?.set('min', this.minScale);
        } else if (this.maxScale != null && value > this.maxScale) {
            this.maxScale = value;
            this.yAxis?.set('max', this.maxScale);
        }
    }

    private processStatisticComponent(): void {
        this.statisticComponent.periodRef = this.statisticComponent.periodRef || this.periodRef;
        if (this.statisticComponent.periodRef) {
            this.statisticComponent.startDateFieldRef = null;
            this.statisticComponent.endDateFieldRef = null;
        }
        this.statisticComponent.query = this.statisticComponent.query || this.query;
        this.statisticFieldsNames = [this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryFieldRef, this.statisticComponent.periodRef];
        this.subscription = this.fieldService.subscribeToFields(this.statisticFieldsNames).subscribe(fieldsMap => {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.refreshStatistic(fieldsMap);
            this.intervalId = setInterval(() => {
                this.refreshStatistic(fieldsMap);
            }, 30000);
        });
    }

    private refreshStatistic(fieldsMap: any): void {
        this.statisticService.getStatisticValue(this.statisticComponent, fieldsMap, this.thing, fieldsMap[this.queryFieldRef])
            .then(value => {
                let values = this.microChartService.handleStatisticItems(value);
                if (values?.length) {
                    values = values.map(v => {
                        if (this.statisticComponent.filter) {
                            v.value = this.loaderPipe.transform(v.value, this.statisticComponent.filter, true);
                        }
                        v['color'] = this.getChartColor(v.value, v.severity);
                        return v;
                    });
                    values[values.length - 1].last = true;
                }
                this.series.data.setAll(values);
                this.xAxis.data.setAll(values);
                if (this.layout == MicroChartLayout.LINE) {
                    this.series.set('fill', this.am5.color(values[values.length - 1]?.['color']));
                    this.series.set('stroke', this.am5.color(values[values.length - 1]?.['color']));
                }
            });
    }

    private initXYChart(): void {
        let chart = this.root.container.children.push(this.am5xy.XYChart.new(this.root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none'
        }));
        let xAxis;
        if (this.metricComponent) {
            xAxis = chart.xAxes.push(this.am5xy.DateAxis.new(this.root, {
                baseInterval: { timeUnit: 'day', count: 1 },
                renderer: this.am5xy.AxisRendererX.new(this.root, {})
            }));
        } else if (this.statisticComponent) {
            xAxis = chart.xAxes.push(this.am5xy.CategoryAxis.new(this.root, {
                categoryField: 'category',
                renderer: this.am5xy.AxisRendererX.new(this.root, {})
            }));
        }
        let yAxisProperties = {
            renderer: this.am5xy.AxisRendererY.new(this.root, {})
        }
        if (this.layout == MicroChartLayout.LINE && (this.minScale || this.maxScale)) {
            yAxisProperties['strictMinMax'] = true;
            yAxisProperties['min'] = this.minScale;
            yAxisProperties['max'] = this.maxScale;
            yAxisProperties['extraMin'] = 0.1;
            yAxisProperties['extraMax'] = 0.1;
            yAxisProperties['autoZoom'] = false;
        }
        let yAxis = chart.yAxes.push(this.am5xy.ValueAxis.new(this.root, yAxisProperties));
        let cursor = chart.set('cursor',
            this.am5xy.XYCursor.new(this.root, {
                xAxis: xAxis
            })
        );
        cursor.lineX.set('visible', true);
        let series;
        if (this.layout == MicroChartLayout.LINE) {
            series = this.setLineSeries(chart, xAxis, yAxis);
        } else { // MicroChartLayout.BARS
            series = this.setColumnSeries(chart, xAxis, yAxis);
        }
        series.appear();
        chart.appear(1000, 100);
        this.series = series;
        this.xAxis = xAxis;
        this.yAxis = yAxis;
    }

    private initPieChart(): void {
        let chart = this.root.container.children.push(this.am5percent.PieChart.new(this.root, {
            paddingRight: 5,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
        }));
        let properties = {
            valueField: 'value',
            tooltip: this.am5.Tooltip.new(this.root, {
                visible: true
            })
        };
        if (this.statisticComponent) {
            properties['categoryField'] = 'category';
        } else {
            properties['categoryField'] = 'timestamp';
        }
        let series = chart.series.push(this.am5percent.PieSeries.new(this.root, properties));
        if (this.statisticComponent) {
            series.get('tooltip').setAll({
                labelText: '{category}: [bold]{value}'
            });
        }
        series.slices.template.adapters.add('fill', (fill, target) => {
            return this.am5.color(target.dataItem.dataContext.color);
        });
        series.slices.template.adapters.add('stroke', (fill, target) => {
            return this.am5.color(target.dataItem.dataContext.color);
        });
        series.slices.template.states.create("active", {
            shiftRadius: 0
        });
        this.series = series;
    }

    private setLineSeries(chart: any, xAxis: any, yAxis: any): any {
        let properties = {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            tooltip: this.am5.Tooltip.new(this.root, {
                visible: true
            })
        };
        if (this.statisticComponent) {
            properties['categoryXField'] = 'category';
        } else {
            properties['valueXField'] = 'timestamp';
        }
        let series = chart.series.push(this.am5xy.LineSeries.new(this.root, properties));
        if (this.statisticComponent) {
            series.get('tooltip').setAll({
                labelText: '{category}: [bold]{value}'
            });
        }
        series.strokes.template.setAll({
            strokeWidth: 2
        });
        series.bullets.push((root, series, dataItem) => {
            if (dataItem.dataContext.last) {
                return this.am5.Bullet.new(root, {
                    sprite: this.am5.Circle.new(root, {
                        radius: 4,
                        fill: series.get("fill")
                    })
                });
            }
        });
        return series;
    }

    private setColumnSeries(chart: any, xAxis: any, yAxis: any): any {
        let properties = {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            tooltip: this.am5.Tooltip.new(this.root, {
                visible: true
            })
        };
        if (this.statisticComponent) {
            properties['categoryXField'] = 'category';
        } else {
            properties['valueXField'] = 'timestamp';
        }
        let series = chart.series.push(this.am5xy.ColumnSeries.new(this.root, properties));
        if (this.statisticComponent) {
            series.get('tooltip').setAll({
                labelText: '{category}: [bold]{value}'
            });
        }
        series.columns.template.adapters.add('fill', (fill, target) => {
            if (target.dataItem.dataContext.last) {
                return this.am5.color(target.dataItem.dataContext.color);
            } else {
                return this.am5.color(this.CHART_COLORS.GREY);
            }

        });
        series.columns.template.adapters.add('stroke', (fill, target) => {
            if (target.dataItem.dataContext.last) {
                return this.am5.color(target.dataItem.dataContext.color);
            } else {
                return this.am5.color(this.CHART_COLORS.GREY);
            }
        });
        return series;
    }

    private getChartColor(value: any, severity: ValueRangeSeverity): string {
        if (this.color) {
            return this.color;
        }
        if (severity) {
            return window['valueColors']?.[severity] || this.CHART_COLORS[severity] || this.CHART_COLORS['DEFAULT'];
        } else if (this.wearMetricStandardUsage && !isNaN(Number(value))) {
            return (value < this.wearMetricStandardUsage) ? this.CHART_COLORS['DEFAULT'] : (window['valueColors']?.['CRITICAL'] || this.CHART_COLORS['CRITICAL']);
        } else {
            return window['chartPalette']?.[0] || this.CHART_COLORS['DEFAULT'];
        }
    }

    private updateAxisProperties(metricPeriod: string) {
        let baseInterval: any;
        let tooltipText: string;
        const shortTimeFormat = moment.localeData(this.locale).longDateFormat('LT');
        const longTimeFormat = moment.localeData(this.locale).longDateFormat('LTS');
        const dateFormat = this.toAmChartsDateFormat(moment.localeData(this.locale).longDateFormat('L'));
        const unitText = this.metricComponent?.unit ? ` ${this.metricComponent.unit}` : '';
        if (metricPeriod) {
            if (metricPeriod.includes('MINUTE')) {
                baseInterval = { timeUnit: 'minute', count: 1 };
                tooltipText = `{timestamp.formatDate("${dateFormat} ${shortTimeFormat}")}: [bold]{value}${unitText}`;
            } else if (metricPeriod.includes('HOUR')) {
                baseInterval = { timeUnit: 'hour', count: 1 };
                tooltipText = `{timestamp.formatDate("${dateFormat} ${shortTimeFormat}")}: [bold]{value}${unitText}`;
            } else if (metricPeriod.includes('DAY')) {
                baseInterval = { timeUnit: 'day', count: 1 };
                tooltipText = `{timestamp.formatDate("${dateFormat}")}: [bold]{value}${unitText}`;
            } else if (metricPeriod.includes('MONTH')) {
                baseInterval = { timeUnit: 'month', count: 1 };
                tooltipText = `{timestamp.formatDate("${dateFormat}")}: [bold]{value}${unitText}`;
            } else if (metricPeriod.includes('YEAR')) {
                baseInterval = { timeUnit: 'year', count: 1 };
                tooltipText = `{timestamp.formatDate("yyyy")}: [bold]{value}${unitText}`;
            } else {
                baseInterval = { timeUnit: 'second', count: 1 };
                tooltipText = `{timestamp.formatDate("${dateFormat} ${longTimeFormat}")}: [bold]{value}${unitText}`;
            }
        } else {
            baseInterval = { timeUnit: 'day', count: 1 };
            tooltipText = `{timestamp.formatDate("${dateFormat} ${longTimeFormat}")}: [bold]{value}${unitText}`;
        }
        this.xAxis.set('baseInterval', baseInterval);
        this.series.get('tooltip').setAll({
            labelText: tooltipText
        });
    }

    private updatePieProperties(metricPeriod: string) {
        let tooltipText: string;
        const shortTimeFormat = moment.localeData(this.locale).longDateFormat('LT');
        const longTimeFormat = moment.localeData(this.locale).longDateFormat('LTS');
        const dateFormat = this.toAmChartsDateFormat(moment.localeData(this.locale).longDateFormat('L'));
        if (metricPeriod) {
            if (metricPeriod.includes('MINUTE')) {
                tooltipText = `{timestamp.formatDate("${dateFormat} ${shortTimeFormat}")}: [bold]{value}`;
            } else if (metricPeriod.includes('HOUR')) {
                tooltipText = `{timestamp.formatDate("${dateFormat} ${shortTimeFormat}")}: [bold]{value}`;
            } else if (metricPeriod.includes('DAY')) {
                tooltipText = `{timestamp.formatDate("${dateFormat}")}: [bold]{value}`;
            } else if (metricPeriod.includes('MONTH')) {
                tooltipText = `{timestamp.formatDate("${dateFormat}")}: [bold]{value}`;
            } else if (metricPeriod.includes('YEAR')) {
                tooltipText = `{timestamp.formatDate("yyyy")}: [bold]{value}`;
            } else {
                tooltipText = `{timestamp.formatDate("${dateFormat} ${longTimeFormat}")}: [bold]{value}`;
            }
        } else {
            tooltipText = `{timestamp.formatDate("${dateFormat} ${longTimeFormat}")}: [bold]{value}`;
        }
        this.series.get('tooltip').setAll({
            labelText: tooltipText
        });
    }


    private toAmChartsDateFormat(dateString: string): string {
        return [...dateString].map(l => {
            if (['Y', 'D'].includes(l)) {
                return l.toLocaleLowerCase();
            } else {
                return l;
            }
        }).join('');
    }
}

export enum MicroChartLayout {
    LINE = 'LINE',
    BARS = 'BARS',
    PIE = 'PIE'
}